export const DaysFirstLogin = {
  Field: "DaysFirstLogin",
  Name: "Dias do 1º Login",
  Type: "number",
};
export const DaysLastLogin = {
  Field: "DaysLastLogin",
  Name: "Dias do ultimo Login",
  Type: "number",
};
export const dateAdmission = {
  Field: "dateAdmission",
  Name: "Data de Admissão",
  Type: "date",
};
export const Store = {
  Field: "IdJobLocation",
  Name: "Localização",
  Type: "job-locations",
};
export const Department = {
  Field: "IdJobDepartment",
  Name: "Departamento",
  Type: "job-departments",
};
export const Function = {
  Field: "IdJobFunction",
  Name: "Função",
  Type: "job-functions",
};
export const CostCenter = {
  Field: "IdJobCostCenter",
  Name: "Centro de Custo",
  Type: "job-costcenters",
};
export const Course = {
  Field: "Course",
  Name: "Curso",
  Type: "courses",
};
export const dateInscription = {
  Field: "dateInscription",
  Name: "Data de Inscrição",
  Type: "date",
};
export const dateConclusion = {
  Field: "dateConclusion",
  Name: "Data de conclusão",
  Type: "date",
};
export const DaysdateConclusion = {
  Field: "DaysdateConclusion",
  Name: "Dias Data de conclusão",
  Type: "number",
};
export const DaysdateInscription = {
  Field: "DaysdateInscription",
  Name: "Dias Data de inscrição",
  Type: "number",
};
export const Grade = {
  Field: "Grade",
  Name: "Nota",
  Type: "number",
};

export const SegmentFilterTypes = [
  DaysFirstLogin,
  DaysLastLogin,
  dateAdmission,
  Store,
  Department,
  Function,
  CostCenter,
  Course,
  dateInscription,
  dateConclusion,
  DaysdateConclusion,
  DaysdateInscription,
  Grade,
];

export function GetSegmentFilterTypesTypeNameById(id) {
  for (let index = 0; index < SegmentFilterTypes.length; index++) {
    if (SegmentFilterTypes[index].TypeId === id) {
      return SegmentFilterTypes[index].Name;
    }
  }
  return "";
}

export function GetSegmentFilterTypesTypeTypeByField(field) {
  for (let index = 0; index < SegmentFilterTypes.length; index++) {
    if (SegmentFilterTypes[index].Field === field) {
      return SegmentFilterTypes[index].Type;
    }
  }
  return "";
}
