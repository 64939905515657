export const Notification = {
  TypeId: 1,
  Name: "Notificação",
};
export const Email = {
  TypeId: 2,
  Name: "Email",
};

export const WorkflowMessageTypes = [Email];

export function GetWorkflowMessageTypeNameById(id) {
  for (let index = 0; index < WorkflowMessageTypes.length; index++) {
    if (WorkflowMessageTypes[index].TypeId === id) {
      return WorkflowMessageTypes[index].Name;
    }
  }
  return "";
}
