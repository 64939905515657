export const Daily = {
  TypeId: 1,
  Name: "Diário",
};
export const Monthly = {
  TypeId: 2,
  Name: "Mensal",
};
export const Annual = {
  TypeId: 3,
  Name: "Anual",
};

export const WorkflowRepetitionTypes = [Daily, Monthly, Annual];

export function GetWorkflowRepetitionTypeNameById(id) {
  for (let index = 0; index < WorkflowRepetitionTypes.length; index++) {
    if (WorkflowRepetitionTypes[index].TypeId === id) {
      return WorkflowRepetitionTypes[index].Name;
    }
  }
  return "";
}
