<template>
  <div>
    <app-page-title>
      <h1>Workflows</h1>
    </app-page-title>
    <v-container grid-list-md>
      <div class="k-grid k-grid-toolbar">
        <app-form-modal
          class-icon="icon-add"
          :title-dialog="`${isNew ? 'Adicionar' : 'Editar'} Workflow`"
          :confirmAction="saveInstance"
          :cancelAction="clearData"
          :formValid="!$v.Instance.$error"
          ref="modal"
        >
          <v-layout wrap row>
            <v-flex xs12>
              <v-text-field
                v-model="Instance.Name"
                label="Nome"
                :error="$v.Instance.Name.$error"
                @blur="$v.Instance.Name.$touch()"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs6>
              <date-picker
                class="mr-2"
                v-model="Instance.DateStart"
                title="Data de Inicio"
                :error="$v.Instance.DateStart.$error"
                @blur="$v.Instance.DateStart.$touch()"
              ></date-picker>
            </v-flex>
            <v-flex xs6>
              <date-picker
                class="ml-2"
                clearable
                v-model="Instance.DateEnd"
                title="Data de Fim"
              ></date-picker>
            </v-flex>
            <v-flex xs12> </v-flex>
            <v-flex> </v-flex>
          </v-layout>
          <v-layout wrap row>
            <v-flex xs6>
              <v-select
                class="mr-2"
                :items="segments"
                item-text="Name"
                item-value="IdWorkflowSegment"
                v-model="Instance.IdWorkflowSegment"
                label="Segmento"
                :error="$v.Instance.IdWorkflowSegment.$error"
                @blur="$v.Instance.IdWorkflowSegment.$touch()"
              ></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select
                class="ml-2"
                :items="messages"
                item-text="Name"
                item-value="IdWorkflowMessage"
                v-model="Instance.IdWorkflowMessage"
                :error="$v.Instance.IdWorkflowMessage.$error"
                @blur="$v.Instance.IdWorkflowMessage.$touch()"
                label="Mensagem"
              ></v-select>
            </v-flex>
            <v-flex xs12> </v-flex>
            <v-flex> </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs6>
              <v-checkbox
                v-model="Instance.IsRepeatable"
                label="Repetição"
              ></v-checkbox>
            </v-flex>
            <v-flex xs6 v-if="Instance.IsRepeatable">
              <v-layout wrap row fill-height align-center>
                <v-flex>
                  <v-radio-group v-model="Instance.RepetitionTypeId">
                    <v-radio
                      v-for="type in types"
                      :key="type.TypeId"
                      :label="type.Name"
                      :value="type.TypeId"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex>
                  <v-text-field
                    label="Valor da repetição"
                    v-model="Instance.RepetitionValue"
                    type="number"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </app-form-modal>
      </div>
      <Grid
        :style="{ height: 'auto' }"
        :data-items="Items"
        :columns="Columns"
        :pageable="true"
        :skip="skip"
        :take="take"
        :total="grid.pagging.total"
        @pagechange="pageChange"
        :sortable="false"
        :filterable="false"
        @edit="edit"
        @remove="remove"
      >
        <template slot="typeCell" slot-scope="{ props }">
          <td :class="props.className">
            {{ getTypeName(props.dataItem) }}
          </td>
        </template>
        <template slot="dateEndCell" slot-scope="{ props }">
          <td :class="props.className">
            {{ props.dataItem.DateEnd ? props.dataItem.DateEnd : "N/A" }}
          </td>
        </template>
        <GridNoRecords>Não existe workflows</GridNoRecords>
      </Grid>
    </v-container>
  </div>
</template>
<script>
import * as kendo from "@progress/kendo-ui";
import { required, minLength, minValue } from "vuelidate/lib/validators";
import FormModalComponent from "@/components/modals/form-modal-large";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import workflowsService from "@/services/api/workflowsService";
import workflowSegmentsService from "@/services/api/workflowSegmentsService";
import workflowMessagesService from "@/services/api/workflowMessagesService";
import {
  WorkflowRepetitionTypes,
  GetWorkflowRepetitionTypeNameById,
} from "@/models/workflow-repetition-types";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import GridHelper from "@/utilities/grid";
import DatePicker from "@/components/forms/date-picker";

export default {
  components: {
    "app-form-modal": FormModalComponent,
    "date-picker": DatePicker,
  },
  data() {
    return {
      types: WorkflowRepetitionTypes,
      Instance: {},
      segments: [],
      messages: [],
      Items: [],
      grid: {
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      Columns: [
        {
          field: "Name",
          title: "Nome",
        },
        {
          field: "DateStart",
          title: "Data de Inicio",
        },
        {
          field: "DateEnd",
          title: "Data de fim",
          cell: "dateEndCell",
        },
        {
          field: "RepetitionTypeId",
          title: "Tipo",
          cell: "typeCell",
        },
        {
          field: "RepetitionValue",
          title: "Valor",
        },
        {
          cell: EditCommandCell,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(3),
      },
      DateStart: {
        required,
      },
      DateEnd: {
        required,
      },
      IdWorkflowSegment: { required, minValue: minValue(1) },
      IdWorkflowMessage: { required, minValue: minValue(1) },
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdWorkflow == 0;
    },
    skip() {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take() {
      return this.grid.pagging.pageSize;
    },
  },
  async created() {
    this.$v.$touch();
    await this.loadSegments();
    await this.loadMessages();
    this.resetInstance();
    await this.loadItems();
  },
  methods: {
    async loadSegments() {
      await workflowSegmentsService
        .Get()
        .then((res) => {
          this.segments = res.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    async loadMessages() {
      await workflowMessagesService
        .Get()
        .then((res) => {
          this.messages = res.data.Items;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    getTypeName(item) {
      return GetWorkflowRepetitionTypeNameById(item.RepetitionTypeId);
    },
    openModal() {
      this.$refs.modal.open();
    },
    clearData(callback) {
      this.resetInstance();
      callback(true);
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.readUserLogs();
    },
    async loadItems() {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging
      );
      let data = GridHelper.ParseOptions(options);
      await workflowsService
        .Get(data)
        .then((res) => {
          this.Items = res.data.Items;
          this.grid.pagging.total = res.data.Total;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    resetInstance() {
      this.Instance = {
        IdWorkflow: 0,
        RepetitionTypeId: 1,
        RepetitionValue: 1,
        DateStart: null,
        DateEnd: null,
        IdSegment: 0,
        IdMessage: 0,
      };
    },
    async saveInstance(callBack) {
      if (this.isNew) {
        await workflowsService
          .Create(this.Instance)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou o workflow com sucesso!"
            );
            this.loadItems();
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await workflowsService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou o workflow com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) => s.IdWorkflowMessage == this.Instance.IdWorkflowMessage
            );
            if (index >= 0) {
              this.Items[index] = { ...response.data.Instance };
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    deleteInstance(item) {
      var id = item.IdWorkflow;
      kendo.confirm("Tem a certeza que quer apagar o workflow?").done(() => {
        workflowsService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou o workflow com sucesso!"
            );
            this.loadItems();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
};
</script>

<style scoped></style>
