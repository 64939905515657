<template>
  <v-text-field type="number" :value="value" @input="emit"></v-text-field>
</template>
<script>
export default {
  props: ["value"],

  methods: {
    emit(evt) {
      this.$emit("input", evt);
    },
  },
};
</script>
