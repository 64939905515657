<template>
  <v-container grid-list-md>
    <div class="k-grid k-grid-toolbar">
      <app-form-modal
        class-icon="icon-add"
        titleButton="Adicionar Segmento"
        :Name-dialog="`${isNew ? 'Adicionar' : 'Editar'} Segmento`"
        :confirmAction="saveInstance"
        :cancelAction="clearData"
        :formValid="!$v.Instance.$error"
        ref="modal"
      >
        <v-layout wrap>
          <v-flex xs12 pr-2>
            <v-text-field
              required
              v-model="Instance.Name"
              label="Nome"
              :error="$v.Instance.Name.$error"
              @blur="$v.Instance.Name.$touch()"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </app-form-modal>
    </div>
    <Grid
      :style="{ height: 'auto' }"
      :data-items="Items"
      :columns="Columns"
      :pageable="true"
      :detail="cellTemplate"
      @expandchange="expandChange"
      :expand-field="'expanded'"
      :total="grid.pagging.total"
      @pagechange="pageChange"
      @edit="edit"
      @remove="remove"
    >
      <GridNoRecords>Não existem segmentos</GridNoRecords>
    </Grid>
  </v-container>
</template>
<script>
import Vue from "vue";
import * as kendo from "@progress/kendo-ui";
import { required, minLength } from "vuelidate/lib/validators";
import FormModalComponent from "@/components/modals/form-modal";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import GridHelper from "@/utilities/grid";
import workflowSegmentsService from "@/services/api/workflowSegmentsService";

import WorkflowSegmentFiltersGridComponent from "./grid";
Vue.component(
  "app-workflow-segment-filters-grid",
  WorkflowSegmentFiltersGridComponent
);
const componentInstance = Vue.component(
  "app-workflow-segment-filters-grid-component",
  {
    props: ["dataItem"],
    template: `<app-workflow-segment-filters-grid :dataItem="dataItem"></app-workflow-segment-filters-grid>`,
  }
);

export default {
  components: {
    "app-form-modal": FormModalComponent,
  },
  props: { survey: { type: Object, default: null } },
  data() {
    return {
      Instance: { Name: "" },
      cellTemplate: componentInstance,
      Items: [],
      grid: {
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      Columns: [
        {
          field: "Name",
          title: "Nome",
        },
        {
          cell: EditCommandCell,
          width: 75,
          Name: "",
        },
        {
          cell: DeleteCommandCell,
          width: 75,
          Name: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Name: { required, minLength: minLength(3) },
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdWorkflowSegment == 0;
    },
  },
  async created() {
    this.resetInstance();
    this.$v.$touch();
    await this.loadItems();
  },
  mounted() {},
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    expandChange(ev) {
      Vue.set(ev.dataItem, ev.target.$props.expandField, ev.value);
    },
    clearData(callback) {
      this.resetInstance();
      callback(true);
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.readsurveyLogs();
    },
    async loadItems() {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging
      );
      let data = GridHelper.ParseOptions(options);
      data.Filters = [];
      data.Order = [{ Field: "OrderId", Direction: "ASC" }];
      await workflowSegmentsService
        .Get(data)
        .then((res) => {
          this.Items = res.data.Items;
          this.grid.pagging.total = res.data.Total;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    resetInstance() {
      this.Instance = {
        IdWorkflowSegment: 0,
        Name: "",
      };
    },
    async saveInstance(callBack) {
      if (this.isNew) {
        await workflowSegmentsService
          .Create(this.Instance)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Adicionou o segmento com sucesso!"
            );
            this.loadItems();
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await workflowSegmentsService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou o segmento com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) => s.IdWorkflowSegment == this.Instance.IdWorkflowSegment
            );
            if (index >= 0) {
              this.Items[index] = { ...response.data.Instance };
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    deleteInstance(item) {
      var id = item.IdWorkflowSegment;
      kendo.confirm("Tem a certeza que quer apagar o segmento?").done(() => {
        workflowSegmentsService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou o segmento com sucesso!"
            );
            this.loadItems();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
};
</script>

<style scoped></style>
