<template>
  <div>
    <app-page-title>
      <h1>Mensagens</h1>
    </app-page-title>
    <v-container grid-list-md>
      <div class="k-grid k-grid-toolbar">
        <app-form-modal
          class-icon="icon-add"
          :title-dialog="`${isNew ? 'Adicionar' : 'Editar'} Mensagem`"
          :confirmAction="saveInstance"
          :cancelAction="clearData"
          :formValid="!$v.Instance.$error"
          @opened="initHtml()"
          @closed="renderHtml = false"
          ref="modal"
        >
          <v-layout wrap row>
            <v-flex xs6>
              <v-text-field
                class="mr-2"
                label="Nome"
                v-model="Instance.Name"
                :error="$v.Instance.Name.$error"
                @blur="$v.Instance.Name.$touch()"
              >
              </v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-select
                class="ml-2"
                v-model="Instance.TypeId"
                :items="types"
                label="Tipo"
                item-text="Name"
                item-value="TypeId"
                required
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                label="Assunto"
                v-model="Instance.Subject"
                :error="$v.Instance.Subject.$error"
                @blur="$v.Instance.Subject.$touch()"
              >
              </v-text-field>
            </v-flex>
            <v-flex v-if="renderHtml">
              <label>Mensagem</label>
              <kendo-editor
                :resizable-content="false"
                :resizable-toolbar="false"
                v-model="Instance.Message"
                rows="10"
              ></kendo-editor>
            </v-flex>
          </v-layout>
        </app-form-modal>
      </div>
      <Grid
        :style="{ height: 'auto' }"
        :data-items="Items"
        :columns="Columns"
        :pageable="true"
        :skip="skip"
        :take="take"
        :total="grid.pagging.total"
        @pagechange="pageChange"
        :sortable="false"
        :filterable="false"
        @edit="edit"
        @remove="remove"
      >
        <template slot="typeCell" slot-scope="{ props }">
          <td :class="props.className">
            {{ getTypeName(props.dataItem) }}
          </td>
        </template>
        <GridNoRecords>Não existe mensagens</GridNoRecords>
      </Grid>
    </v-container>
  </div>
</template>
<script>
import * as kendo from "@progress/kendo-ui";
import { required, minLength } from "vuelidate/lib/validators";
import FormModalComponent from "@/components/modals/form-modal-large";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import workflowmessagesService from "@/services/api/workflowMessagesService";
import {
  WorkflowMessageTypes,
  GetWorkflowMessageTypeNameById,
} from "@/models/workflow-message-types";
import EditCommandCell from "@/components/grid/edit-command.js";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import GridHelper from "@/utilities/grid";

export default {
  components: {
    "app-form-modal": FormModalComponent,
  },
  data() {
    return {
      renderHtml: false,
      types: WorkflowMessageTypes,
      Instance: {},
      Items: [],
      grid: {
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      Columns: [
        {
          field: "Name",
          title: "Nome",
        },
        {
          field: "Subject",
          title: "Assunto",
        },
        {
          field: "Message",
          title: "Mensagem",
        },
        {
          field: "TypeId",
          title: "Tipo",
          cell: "typeCell",
        },
        {
          cell: EditCommandCell,
          width: 75,
          title: "",
        },
        {
          cell: DeleteCommandCell,
          width: 75,
          title: "",
        },
      ],
    };
  },
  validations: {
    Instance: {
      Name: {
        required,
        minLength: minLength(3),
      },
      Subject: {
        required,
      },
    },
  },
  computed: {
    isNew() {
      return this.Instance != null && this.Instance.IdWorkflowMessage == 0;
    },
    skip() {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take() {
      return this.grid.pagging.pageSize;
    },
  },
  async created() {
    this.resetInstance();
    this.$v.$touch();
    await this.loadItems();
  },
  methods: {
    initHtml() {
      setTimeout(() => (this.renderHtml = true), 500);
    },
    getTypeName(item) {
      return GetWorkflowMessageTypeNameById(item.TypeId);
    },
    openModal() {
      this.$refs.modal.open();
    },
    clearData(callback) {
      this.resetInstance();
      callback(true);
    },
    edit(item) {
      this.Instance = { ...item.dataItem };
      this.openModal();
    },
    remove(item) {
      this.deleteInstance(item.dataItem);
    },
    pageChange(ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.readUserLogs();
    },
    async loadItems() {
      let options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging
      );
      let data = GridHelper.ParseOptions(options);
      await workflowmessagesService
        .Get(data)
        .then((res) => {
          this.Items = res.data.Items;
          this.grid.pagging.total = res.data.Total;
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        });
    },
    resetInstance() {
      this.Instance = {
        IdWorkflowMessage: 0,
        TypeId: 1,
        Subject: "",
        Message: "",
      };
    },
    async saveInstance(callBack) {
      if (this.isNew) {
        await workflowmessagesService
          .Create(this.Instance)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Criou a mensagem com sucesso!"
            );
            this.loadItems();
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      } else {
        await workflowmessagesService
          .Update(this.Instance)
          .then((response) => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Editou a mensagem com sucesso!"
            );
            const index = this.Items.findIndex(
              (s) => s.IdWorkflowMessage == this.Instance.IdWorkflowMessage
            );
            if (index >= 0) {
              this.Items[index] = { ...response.data.Instance };
            }
            this.resetInstance();
            callBack(true);
          })
          .catch((error) => {
            callBack(false);
            notificationServiceBus.showError(error.response);
          });
      }
    },
    deleteInstance(item) {
      var id = item.IdWorkflowMessage;
      kendo.confirm("Tem a certeza que quer apagar a mensagem?").done(() => {
        workflowmessagesService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Apagou a mensagem com sucesso!"
            );
            this.loadItems();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
  },
};
</script>

<style scoped></style>
